const quarters = {
  "1": {
    months: [1, 2, 3],
  },
  "2": {
    months: [4, 5, 6],
  },
  "3": {
    months: [7, 8, 9],
  },
  "4": {
    months: [10, 11, 12],
  },
};

export const calculateCompanies = (active_params, data) => {
  const modelOfSum = [];
  data.forEach((country) => {
    let tmp_country = getReportsOfOneCountry(country, active_params);

    modelOfSum.push(tmp_country);
  });

  return modelOfSum;
};

const getReportsOfOneCountry = (country, active_params) => {
  const calculatedCOuntry = {
    name: country.name,
    report: {
      PV: 0,
      NV: 0,
      RUC: 0,
    },
  };

  if (active_params.active_tab === "months") {
    let tmpReport = {
      PV: 0,
      NV: 0,
      RUC: 0,
    };
    Object.keys(country.reports).forEach((x) => {
      if (x == active_params.month) {
        tmpReport = country.reports[x];
      }
    });

    calculatedCOuntry.report = tmpReport;
  } else if (active_params.active_tab === "years") {
    let tmpReport = {
      PV: 0,
      NV: 0,
      RUC: 0,
    };
    Object.keys(country.reports).forEach((x) => {
      tmpReport.PV += country.reports[x].PV;
      tmpReport.NV += country.reports[x].NV;
      tmpReport.RUC += country.reports[x].RUC;
    });

    calculatedCOuntry.report = tmpReport;
  } else {
    let tmpReport = {
      PV: 0,
      NV: 0,
      RUC: 0,
    };
    quarters[active_params.quarter.toString()].months.forEach((z) => {
      Object.keys(country.reports).forEach((x) => {
        if (x == z) {
          tmpReport.PV += country.reports[x].PV;
          tmpReport.NV += country.reports[x].NV;
          tmpReport.RUC += country.reports[x].RUC;
        }
      });
    });

    calculatedCOuntry.report = tmpReport;
  }

  return calculatedCOuntry;
};

export const sales = (active_params, data, planData) => {
  let model_of_sum = {
    PLANIRANA_NABAVNA_VR: 0,
    PLANIRANI_RUC: 0,
    PRODAJNA_VRIJEDNOST: 0,
    PRODAJNA_VRIJEDNOST_AL: 0,
    PRODAJNA_VRIJEDNOST_CU: 0,
    PRODAJNA_VRIJEDNOST_EN: 0,
    PRODAJNA_VRIJEDNOST_IN: 0,
    PRODAJNA_VRIJEDNOST_PVC: 0,
    PRODANE_TONE: 0,
    PRODANE_TONE_PVC: 0,
    PRODANE_TONE_CU: 0,
    PRODANE_TONE_AL: 0,
    PRODANI_KILOMETRI: 0,
    STVARNI_RUC: 0,
    STVARNA_NABAVNA_VR: 0
  };

  if (active_params.active_tab === "months") {
    Object.keys(model_of_sum).forEach((key) => {
      model_of_sum[key] = data[active_params.month.toString()][key];
    });

    return {
      model_of_sum,
      plan: planSales(planData, active_params),
    };
  } else if (active_params.active_tab === "years") {
    Object.keys(data).forEach((x) => {
      Object.keys(data[x]).forEach((y) => {
        model_of_sum[y] += +data[x][y];
      });
    });
    return { model_of_sum, plan: planSales(planData, active_params) };
  } else {
    quarters[active_params.quarter.toString()].months.forEach((z) => {
      Object.keys(data).forEach((x) => {
        if (x == z) {
          Object.keys(data[x]).forEach((y) => {
            model_of_sum[y] += +data[x][y];
          });
        }
      });
    });
    return { model_of_sum, plan: planSales(planData, active_params) };
  }
};

export const production = (active_params, data, planData) => {
  let model_of_sum = {
    KILOMETRI_UKUPNO: 0,
    KILOMETRI_UKUPNO_AL: 0,
    KILOMETRI_UKUPNO_CU: 0,
    KILOMETRI_UKUPNO_EN: 0,
    KILOMETRI_UKUPNO_IN: 0,
    MASAP_SASTAVNICA_AL: 0,
    MASAP_SASTAVNICA_CU: 0,
    MASAP_SASTAVNICA_EN: 0,
    MASAP_SASTAVNICA_IN: 0,
    MASAP_SASTAVNICA_UKUPNO: 0,
    MASA_VAGANO: 0,
    MASA_VAGANO_AL: 0,
    MASA_VAGANO_CU: 0,
    MASA_VAGANO_EN: 0,
    MASA_VAGANO_IN: 0,
  };

  if (active_params.active_tab === "months") {
    Object.keys(model_of_sum).forEach((key) => {
      model_of_sum[key] = data[active_params.month.toString()][key];
    });

    return {
      model_of_sum,
      plan: plan(planData, active_params),
    };
  } else if (active_params.active_tab === "years") {
    Object.keys(data).forEach((x) => {
      Object.keys(data[x]).forEach((y) => {
        model_of_sum[y] += +data[x][y];
      });
    });
    return { model_of_sum, plan: plan(planData, active_params) };
  } else {
    quarters[active_params.quarter.toString()].months.forEach((z) => {
      Object.keys(data).forEach((x) => {
        if (x == z) {
          Object.keys(data[x]).forEach((y) => {
            model_of_sum[y] += +data[x][y];
          });
        }
      });
    });
    return { model_of_sum, plan: plan(planData, active_params) };
  }
};
const planSales = (planData, active_params) => {
  const plan = {
    cost_value: 0,
    sales_value: 0,
  };

  if (active_params.active_tab === "months") {
    plan.cost_value = planData[active_params.month.toString()]?.cost_value;
    plan.sales_value = planData[active_params.month.toString()]?.sales_value;
    return plan;
  } else if (active_params.active_tab === "years") {
    Object.keys(planData).forEach((x) => {
      Object.keys(planData[x]).forEach((y) => {
        plan[y] += +planData[x][y];
      });
    });
    return plan;
  } else {
    quarters[active_params.quarter.toString()].months.forEach((z) => {
      Object.keys(planData).forEach((x) => {
        if (x == z) {
          Object.keys(planData[x]).forEach((y) => {
            plan[y] += +planData[x][y];
          });
        }
      });
    });
    return plan;
  }
};

const currentMonthWorkingDays = (workingDays, currentDate, selectedYear) => {
  if (selectedYear !== new Date().getFullYear()) {
    return +workingDays;
  }
  if (currentDate > +workingDays) {
    return workingDays;
  }
  return currentDate;
};

const getWorkingDaysUntilNow = (active_params, planData) => {
  const date = new Date();
  let workingDays = 0;

  if (active_params.active_tab === "months") {
    workingDays = planData[active_params.month.toString()]?.actual_working_days;
  } else if (active_params.active_tab === "years") {
    let month = 0;
    if (active_params.year === date.getFullYear()) {
      while (month <= date.getMonth()) {
        workingDays = workingDays + +planData[month + 1]?.actual_working_days;
        month++;
      }
    } else {
      while (month < 12) {
        workingDays = workingDays + +planData[month + 1]?.actual_working_days;
        month++;
      }
    }
  } else {
    //Ako je kvartal

    if (
      active_params.year === date.getFullYear() &&
      quarters[active_params.quarter.toString()].months.includes(
        date.getMonth() + 1
      )
    ) {
      workingDays = getWorkingDaysInCurrentQuater(
        planData,
        quarters[active_params.quarter.toString()].months,
        active_params
      );
      // workingDays =
      //   workingDays +
      //   currentMonthWorkingDays(
      //     planData[date.getMonth() + 1]?.working_days,
      //     date.getDate(),
      //     active_params.year
      //   );
    } else {
      quarters[active_params.quarter.toString()].months.map((x) => {
        workingDays = workingDays + +planData[x]?.actual_working_days;
      });
    }
  }

  return workingDays;
};

const getWorkingDaysInCurrentQuater = (planData, quater, active_params) => {
  let workingDays = 0;
  let date = new Date();

  let increment = quater[0];
  do {
    workingDays = workingDays + +planData[increment]?.actual_working_days;
  } while (increment++ < date.getMonth() + 1);

  return workingDays;
};

const plan = (planData, active_params) => {
  const plan = {
    quantity: 0,
    working_days: getWorkingDaysUntilNow(active_params, planData),
    planned_workingDays: 0,
    al: 0,
    cu: 0,
    pvc: 0,
    xlpe: 0
  };

  if (active_params.active_tab === "months") {
    plan.quantity =
      +planData[active_params.month.toString()]?.quantity *
      +planData[active_params.month.toString()]?.working_days;
    plan.planned_workingDays =
      planData[active_params.month.toString()]?.working_days;



      console.log( +planData[active_params.month.toString()]?.al *
      +planData[active_params.month.toString()]?.working_days, "LLLL")

    plan.al =
      +planData[active_params.month.toString()]?.al *
      +planData[active_params.month.toString()]?.working_days;

    plan.cu =
      +planData[active_params.month.toString()]?.cu *
      +planData[active_params.month.toString()]?.working_days;

    plan.pvc =
      +planData[active_params.month.toString()]?.pvc *
      +planData[active_params.month.toString()]?.working_days;

    plan.xlpe =
      +planData[active_params.month.toString()]?.xlpe *
      +planData[active_params.month.toString()]?.working_days;
  } else if (active_params.active_tab === "years") {
    Object.keys(planData).forEach((x) => {
      plan.quantity += +planData[x].quantity * +planData[x].working_days;
      plan.planned_workingDays += +planData[x].working_days;

      plan.cu += +planData[x].cu * +planData[x].working_days;
      plan.al += +planData[x].al * +planData[x].working_days;
      plan.pvc += +planData[x].pvc * +planData[x].working_days;
      plan.xlpe += +planData[x].xlpe * +planData[x].working_days;
    });
  } else {
    quarters[active_params.quarter.toString()].months.forEach((z) => {
      Object.keys(planData).forEach((x) => {
        if (x == z) {
          plan.quantity += +planData[x].quantity * +planData[x].working_days;
          plan.planned_workingDays += +planData[x].working_days;

          plan.cu += +planData[x].cu * +planData[x].working_days;
          plan.al += +planData[x].al * +planData[x].working_days;
          plan.pvc += +planData[x].pvc * +planData[x].working_days;
          plan.xlpe += +planData[x].xlpe * +planData[x].working_days;
        }
      });
    });
  }
  return plan;
};

export const massMaterialsTotal = (planned, real) => {
  if (!planned || !real || +planned === 0 || +real === 0) {
    return "---";
  }
  let decreaseValue = real / 1000 - planned;
  let tmp = (decreaseValue / planned) * 100;

  let tmpString = tmp.toFixed(2);

  if (tmp > 0) {
    tmpString = "+" + tmpString;
  }

  return tmpString + "%";
};

export const calculateRazlika = (planned, real) => {
  if (!planned || !real || +planned === 0 || +real === 0) {
    return "---";
  }
  let tmp = (planned / real) * 100;

  let tmpString = tmp.toFixed(2);

  return tmpString + "%";
};

export const calculateRucPrec = (a, b) => {
  if (!a || !b || +a === 0 || +b === 0) {
    return "---";
  }
  let decreaseValue = a - b;
  let tmp = (decreaseValue / a) * 100;

  let tmpString = tmp.toFixed(2);
  if (tmp > 0) {
    tmpString = "+" + tmpString;
  }

  return tmpString + "%";
};

export const calcPlaniraniRuc = (a, b) => {
  if (!a || !b || +a === 0 || +b === 0) {
    return "---";
  }
  let decreaseValue = b / a;
  let tmp = decreaseValue * 100;

  let tmpString = tmp.toFixed(2);
  if (tmp > 0) {
    tmpString = "+" + tmpString;
  }

  return tmpString + "%";
};

export const calcPlaniraniRuc_2 = (a, b) => {
  if (!a || !b || +a === 0 || +b === 0) {
    return "---";
  }
  let decreaseValue = b / a - 1;
  let tmp = decreaseValue * 100;

  let tmpString = tmp.toFixed(2);
  if (tmp > 0) {
    tmpString = "+" + tmpString;
  }

  return tmpString + "%";
};

export const getByWorkingDays = (a, b, month) => {
  const date = new Date();

  // if (month === date.getMonth() + 1) {
  //   if (date.getDate() > b) {
  //     return a / b;
  //   }
  //   return a / date.getDate();
  // }
  return a / b;
};
