import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  TextInput,
  ActivityIndicator,
  Keyboard,
  ScrollView,
} from "react-native";

// Components
import Header from "../../components/Header";
import ErrorRequestModal from "../../components/ErrorRequestModal";

// Styles
import { colors } from "../../styles/colors";

import { useDispatch, useSelector } from "react-redux";
import { SET_P_PLAN } from "../../redux/modules/Reports/actions";
import { DatePicker, message } from "antd";
import moment from "moment";

// Constants
let { width, height } = Dimensions.get("screen");
if (width > 600) {
  width = 600;
}

export default function ProductionForm({ toggleDrawer, set_toggleDrawer }) {
  const {
    active_params,
    savePlan,
    reports: {
      data: { production },
    },
  } = useSelector((state) => state.Reports);

  const [notWorkingDays, setNotWorkingDays] = useState([]);
  const [openedPicker, setOpenedPicker] = useState("");

  const setInitialPlan = () => {
    return {
      1: {
        name: "Siječanj",
        quantity: production.plan?.months["1"]?.quantity,
        working_days: production.plan?.months["1"]?.working_days,
        cu: production.plan?.months["1"]?.cu,
        pvc: production.plan?.months["1"]?.pvc,
        xlpe: production.plan?.months["1"]?.xlpe,
        al: production.plan?.months["1"]?.al,
        not_working_dates: production.plan?.months["1"]?.not_working_dates,
        actual_working_days: production.plan?.months["1"]?.actual_working_days,
      },
      2: {
        name: "Veljača",
        quantity: production.plan?.months["2"]?.quantity,
        working_days: production.plan?.months["2"]?.working_days,
        cu: production.plan?.months["2"]?.cu,
        pvc: production.plan?.months["2"]?.pvc,
        xlpe: production.plan?.months["2"]?.xlpe,
        al: production.plan?.months["2"]?.al,
        not_working_dates: production.plan?.months["2"]?.not_working_dates,
        actual_working_days: production.plan?.months["2"]?.actual_working_days,
      },
      3: {
        name: "Ožujak",
        quantity: production.plan?.months["3"]?.quantity,
        working_days: production.plan?.months["3"]?.working_days,
        cu: production.plan?.months["3"]?.cu,
        pvc: production.plan?.months["3"]?.pvc,
        xlpe: production.plan?.months["3"]?.xlpe,
        al: production.plan?.months["3"]?.al,
        not_working_dates: production.plan?.months["3"]?.not_working_dates,
        actual_working_days: production.plan?.months["3"]?.actual_working_days,
      },
      4: {
        name: "Travanj",
        quantity: production.plan?.months["4"]?.quantity,
        working_days: production.plan?.months["4"]?.working_days,
        cu: production.plan?.months["4"]?.cu,
        pvc: production.plan?.months["4"]?.pvc,
        xlpe: production.plan?.months["4"]?.xlpe,
        al: production.plan?.months["4"]?.al,
        not_working_dates: production.plan?.months["4"]?.not_working_dates,
        actual_working_days: production.plan?.months["4"]?.actual_working_days,
      },
      5: {
        name: "Svibanj",
        quantity: production.plan?.months["5"]?.quantity,
        working_days: production.plan?.months["5"]?.working_days,
        cu: production.plan?.months["5"]?.cu,
        pvc: production.plan?.months["5"]?.pvc,
        xlpe: production.plan?.months["5"]?.xlpe,
        al: production.plan?.months["5"]?.al,
        not_working_dates: production.plan?.months["5"]?.not_working_dates,
        actual_working_days: production.plan?.months["5"]?.actual_working_days,
      },
      6: {
        name: "Lipanj",
        quantity: production.plan?.months["6"]?.quantity,
        working_days: production.plan?.months["6"]?.working_days,
        cu: production.plan?.months["6"]?.cu,
        pvc: production.plan?.months["6"]?.pvc,
        xlpe: production.plan?.months["6"]?.xlpe,
        al: production.plan?.months["6"]?.al,
        not_working_dates: production.plan?.months["6"]?.not_working_dates,
        actual_working_days: production.plan?.months["6"]?.actual_working_days,
      },
      7: {
        name: "Srpanj",
        quantity: production.plan?.months["7"]?.quantity,
        working_days: production.plan?.months["7"]?.working_days,
        cu: production.plan?.months["7"]?.cu,
        pvc: production.plan?.months["7"]?.pvc,
        xlpe: production.plan?.months["7"]?.xlpe,
        al: production.plan?.months["7"]?.al,
        not_working_dates: production.plan?.months["7"]?.not_working_dates,
        actual_working_days: production.plan?.months["7"]?.actual_working_days,
      },
      8: {
        name: "Kolovoz",
        quantity: production.plan?.months["8"]?.quantity,
        working_days: production.plan?.months["8"]?.working_days,
        cu: production.plan?.months["8"]?.cu,
        pvc: production.plan?.months["8"]?.pvc,
        xlpe: production.plan?.months["8"]?.xlpe,
        al: production.plan?.months["8"]?.al,
        not_working_dates: production.plan?.months["8"]?.not_working_dates,
        actual_working_days: production.plan?.months["8"]?.actual_working_days,
      },
      9: {
        name: "Rujan",
        quantity: production.plan?.months["9"]?.quantity,
        working_days: production.plan?.months["9"]?.working_days,
        cu: production.plan?.months["9"]?.cu,
        pvc: production.plan?.months["9"]?.pvc,
        xlpe: production.plan?.months["9"]?.xlpe,
        al: production.plan?.months["9"]?.al,
        not_working_dates: production.plan?.months["9"]?.not_working_dates,
        actual_working_days: production.plan?.months["9"]?.actual_working_days,
      },
      10: {
        name: "Listopad",
        quantity: production.plan?.months["10"]?.quantity,
        working_days: production.plan?.months["10"]?.working_days,
        cu: production.plan?.months["10"]?.cu,
        pvc: production.plan?.months["10"]?.pvc,
        xlpe: production.plan?.months["10"]?.xlpe,
        al: production.plan?.months["10"]?.al,
        not_working_dates: production.plan?.months["10"]?.not_working_dates,
        actual_working_days: production.plan?.months["10"]?.actual_working_days,
      },
      11: {
        name: "Studeni",
        quantity: production.plan?.months["11"]?.quantity,
        working_days: production.plan?.months["11"]?.working_days,
        cu: production.plan?.months["11"]?.cu,
        pvc: production.plan?.months["11"]?.pvc,
        xlpe: production.plan?.months["11"]?.xlpe,
        al: production.plan?.months["11"]?.al,
        not_working_dates: production.plan?.months["11"]?.not_working_dates,
        actual_working_days: production.plan?.months["11"]?.actual_working_days,
      },
      12: {
        name: "Prosinac",
        quantity: production.plan?.months["12"]?.quantity,
        working_days: production.plan?.months["12"]?.working_days,
        cu: production.plan?.months["12"]?.cu,
        pvc: production.plan?.months["12"]?.pvc,
        xlpe: production.plan?.months["12"]?.xlpe,
        al: production.plan?.months["12"]?.al,
        not_working_dates: production.plan?.months["12"]?.not_working_dates,
        actual_working_days: production.plan?.months["12"]?.actual_working_days,
      },
    };
  };

  console.log(active_params);

  const [plan, setPlan] = useState(setInitialPlan());

  const [successVisible, setSuccessVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setPlan(setInitialPlan(production.plan));
  }, [production.plan]);

  const setPlanValue = (valueIndex, value) => {
    let tmp = { ...plan };

    let tmpValues = valueIndex.split("|");

    if (tmpValues[1] === "A") {
      tmp[+tmpValues[0] + 1].quantity = value;
    } else if (tmpValues[1] === "B") {
      tmp[+tmpValues[0] + 1].working_days = value;
    } else if (tmpValues[1] === "C") {
      tmp[+tmpValues[0] + 1].cu = value;
    } else if (tmpValues[1] === "D") {
      tmp[+tmpValues[0] + 1].al = value;
    } else if (tmpValues[1] === "E") {
      tmp[+tmpValues[0] + 1].pvc = value;
    } else if (tmpValues[1] === "F") {
      tmp[+tmpValues[0] + 1].xlpe = value;
    }else if (tmpValues[1] === "H") {
      tmp[+tmpValues[0] + 1].not_working_dates = value;
    }

    setPlan(tmp);
  };

  const showSuccess = () => {
    setSuccessVisible(true);
    setTimeout(() => {
      setSuccessVisible(false);
    }, 2100);
  };

  const _savePlan = () => {
    Keyboard.dismiss();
    dispatch({
      type: SET_P_PLAN,
      data: { year: active_params.year, months: plan },
      successCallback: () => showSuccess(),
    });
  };

  return (
    <View style={styles.container}>
      {/* Header */}
      <Header
        toggleDrawer={toggleDrawer}
        set_toggleDrawer={set_toggleDrawer}
        name="Plan proizvodnje"
        canGoBack={true}
      />

      {/* Wrapper */}
      <View style={styles.wrapper}>
        <Text
          style={{
            fontSize: 18,
            fontWeight: 500,
            fontFamily: "Poppins",
            textAlign: "left",
            width,
            marginLeft: 32,
            paddingVertical: 14,
            // paddingBottom: 5
          }}
        >
          {"Plan proizvodnje za: " + active_params.year}
        </Text>
        <div style={{ overflow: "scroll", height: "99vh" }}>
          {Object.keys(plan).map((key, index) => {
            console.log(plan[key]);
            return (
              <View key={index}>
                <View
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifayContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      ...styles.valuesColumn,
                      width: "100%",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <View>
                        <Text
                          style={{
                            fontWeight: 500,
                            fontFamily: "Poppins",
                            marginBottom: 16,
                          }}
                        >
                          {plan[key].name}{" "}
                        </Text>
                        <View style={{ position: "relative", marginBottom: 6 }}>
                          <Text style={styles.label}>Ukupna količina</Text>

                          <TextInput
                            onBlur={({ target: { value } }) => {
                              setPlanValue(
                                index + "|A",
                                value.replace(",", ".")
                              );
                            }}
                            defaultValue={plan[index + 1].quantity?.toString()}
                            style={styles.textInputQty}
                            paddingLeft={12}
                            textAlignVertical="center"
                            returnKeyType="done"
                            autoCapitalize="none"
                            keyboardType="decimal-pad"
                          />
                        </View>

                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            marginBottom: 6,
                          }}
                        >
                          <View style={{ position: "relative" }}>
                            <Text style={styles.label}>CU</Text>

                            <TextInput
                              onBlur={({ target: { value } }) => {
                                setPlanValue(
                                  index + "|C",
                                  value.replace(",", ".")
                                );
                              }}
                              defaultValue={plan[index + 1].cu?.toString()}
                              style={styles.textInputWidthCustom}
                              paddingLeft={12}
                              textAlignVertical="center"
                              returnKeyType="done"
                              autoCapitalize="none"
                              keyboardType="decimal-pad"
                            />
                          </View>
                          <View style={{ position: "relative" }}>
                            <Text style={styles.label}>AL</Text>

                            <TextInput
                              onBlur={({ target: { value } }) => {
                                setPlanValue(
                                  index + "|D",
                                  value.replace(",", ".")
                                );
                              }}
                              defaultValue={plan[index + 1].al?.toString()}
                              style={styles.textInputWidthCustom}
                              paddingLeft={12}
                              textAlignVertical="center"
                              returnKeyType="done"
                              autoCapitalize="none"
                              keyboardType="decimal-pad"
                            />
                          </View>
                          <View style={{ position: "relative" }}>
                            <Text style={styles.label}>PVC</Text>

                            <TextInput
                              onBlur={({ target: { value } }) => {
                                setPlanValue(
                                  index + "|E",
                                  value.replace(",", ".")
                                );
                              }}
                              defaultValue={plan[index + 1].pvc?.toString()}
                              style={styles.textInputWidthCustom}
                              paddingLeft={12}
                              textAlignVertical="center"
                              returnKeyType="done"
                              autoCapitalize="none"
                              keyboardType="decimal-pad"
                            />
                          </View>
                          <View style={{ position: "relative" }}>
                            <Text style={styles.label}>XLPE</Text>

                            <TextInput
                              onBlur={({ target: { value } }) => {
                                setPlanValue(
                                  index + "|F",
                                  value.replace(",", ".")
                                );
                              }}
                              defaultValue={plan[index + 1].xlpe?.toString()}
                              style={styles.textInputWidthCustom}
                              paddingLeft={12}
                              textAlignVertical="center"
                              returnKeyType="done"
                              autoCapitalize="none"
                              keyboardType="decimal-pad"
                            />
                          </View>
                        </View>
                        <View style={{ position: "relative" }}>
                          <Text style={styles.label}>Broj dana</Text>
                          <TextInput
                            onBlur={({ target: { value } }) => {
                              setPlanValue(
                                index + "|B",
                                value.replace(",", ".")
                              );
                            }}
                            defaultValue={plan[
                              index + 1
                            ].working_days?.toString()}
                            style={styles.textInputQty}
                            paddingLeft={12}
                            textAlignVertical="center"
                            returnKeyType="done"
                            autoCapitalize="none"
                            keyboardType="decimal-pad"
                          />
                            <Text style={styles.labelNotWorkingDays}>Neradi datumi</Text>
                          <TextInput
                          onBlur={({ target: { value } }) => {
                            validateDayInput(value) ?
                            setPlanValue(
                              index + "|H",
                              value 
                            ) : message.error('Neispravan unos. Ispravni unos je datum odvojen zarezom.', 6);
                          }}
                            defaultValue={plan[
                              index + 1
                            ].not_working_dates?.toString()}
                            style={styles.textInputQty}
                            paddingLeft={12}
                            textAlignVertical="center"
                            returnKeyType="done"
                            autoCapitalize="none"
                          />
                        </View>
                        {/* Kraj boova */}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              paddingVertical: 20,
              paddingTop: 40,
            }}
          >
            {successVisible ? (
              <Text
                style={[
                  styles.sectionName,
                  {
                    color: "#4CAF50",
                    // fontWeight: 600
                  },
                ]}
              >
                Uspješno ste spremili plan!
              </Text>
            ) : (
              <Pressable onPress={_savePlan}>
                {savePlan.status === "loading" ? (
                  <ActivityIndicator color={colors.darkGrey} size="large" />
                ) : (
                  <Text style={styles.saveText}>Spremi</Text>
                )}
              </Pressable>
            )}
          </View>
          <View style={{ height: 270 }}></View>
        </div>
      </View>
      <ErrorRequestModal />
    </View>
  );
}

 

const validateDayInput = (input) => {
  //ako je samo jedan broj bez zareza unos je dobar i ako je manji od 31
  if (/^\d+$/.test(input) && input >= 1 && input <= 31) {
    return true;
  }
 
  //ako je vrijednost prazna undefined unos je ok
  if (!input) {
    return true;
  }
  // Podjela stringa na pojedinačne dane
  const days = input.split(',').map(day => day.trim());
  
  // Provjera je li svaki dan broj između 1 i 31
  const isValid = days.every(day => {
    return /^\d+$/.test(day) && day >= 1 && day <= 31;
  });
  
  return isValid;
};

// Koristiš ovako
const monthName = "Siječanj";


const disabledDate = (current, m, y) => {
  const startDate = moment(`${y}-${m}-01`, "YYYY-MM-DD");
  const endDate = moment(startDate).endOf("month");
  return current < startDate || current > endDate;
};

const styles = StyleSheet.create({
  saveText: {
    color: colors.lightGrey,
    borderRadius: 8,
    backgroundColor: colors.darkGrey,
    width: width - 32,
    textAlign: "center",
    paddingVertical: 18,
    paddingTop: 20,
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  valuesColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: width * 0.5,
  },
  textInputMonth: {
    width: "100%",

    maxWidth: 150,
    minWidth: 90,
    borderWidth: 1,
    borderColor: "#cdcdcd",
    fontWeight: 400,
    fontFamily: "Poppins",
    paddingVertical: 7,
    paddingTop: 9,
    borderRadius: 4,
    paddingLeft: 6,
    marginTop: 0,
    marginBottom: 10,
    color: colors.black,
  },
  textInputQty: {
    width: "100%",

    maxWidth: 300,
    minWidth: 90,
    borderWidth: 1,
    borderColor: "#cdcdcd",
    fontWeight: 400,
    fontFamily: "Poppins",
    paddingVertical: 7,
    paddingTop: 9,
    borderRadius: 4,
    paddingLeft: 6,
    marginTop: 0,
    marginBottom: 10,
    color: colors.black,
  },
  textInputWidthCustom: {
    width: 60,
    borderWidth: 1,
    borderColor: "#cdcdcd",
    fontWeight: 400,
    fontFamily: "Poppins",
    paddingVertical: 7,
    paddingTop: 9,
    borderRadius: 4,
    paddingLeft: 6,
    marginTop: 0,
    marginBottom: 10,
    color: colors.black,
  },

  container: {
    flex: 1,
    backgroundColor: colors.black,
    // paddingTop: 30,
    alignItems: "center",
  },
  wrapper: {
    width,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    alignItems: "center",
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  text: {
    fontSize: 24,
    marginTop: 4,
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
    color: colors.black,
  },
  label: {
    position: "absolute",
    fontSize: 12,
    top: -11,
    left: 8,
    zIndex: 10,
    paddingHorizontal: 4,
    paddingVertical: 2,
    backgroundColor: colors.white,
    fontWeight: 300,
    fontFamily: "Poppins",
  },
  labelNotWorkingDays: {
    position: "absolute",
    fontSize: 12,
    top: 39,
    left: 8,
    zIndex: 10,
    paddingHorizontal: 4,
    paddingVertical: 2,
    backgroundColor: colors.white,
    fontWeight: 300,
    fontFamily: "Poppins",
  },
  materialInputWidth: {
    width: "66px",
  },
});
